import React from "react";
import qs from "query-string";
import { RealtimeModes } from "mobility-toolbox-js/api";
import DiversityHeader from "../../components/DiversityHeader";
import LifeQualityHeader from "../../components/LifeQualityHeader";
import common, { getStyleUrl } from "../common";
import lineIcons from "./lineIcons";
import mapLineIcons from "./mapLineIcons";
import standingVehicleStyleFunc from "./standingVehicleStyleFunc";
import eventsSchematic from "../../events/schematic";
import eventsTopographic from "../../events/topographic";

const {
  REACT_APP_SCHEMATIC_EXTENT,
  REACT_APP_TOPOGRAPHIC_EXTENT,
  REACT_APP_STYLE_SCHEMATIC,
} = process.env;

const schematicExtent = (REACT_APP_SCHEMATIC_EXTENT || "0,0,5469102,3370699") // 1152072,6048052,1433666,6205578"
  .split(",")
  .map((coord) => parseFloat(coord));

const topographicExtent = (
  REACT_APP_TOPOGRAPHIC_EXTENT || "1250562,6106868,1320960,6145316"
)
  .split(",")
  .map((coord) => parseFloat(coord));

const params = qs.parse(window.location.search);

// Temporar info related to Diversity, see SBAHNMW-800
const diversityEndDate = new Date("2024-12-20T03:00:00+02:00");
const diversityStartDate = new Date("2023-06-26T00:01:00+02:00");
const isDiversity = () => {
  if (params.diversity) {
    return true;
  }
  return Date.now() > diversityStartDate && Date.now() < diversityEndDate;
};
const isDiversityTrain = (trajectory) => {
  const paramRake = params.diversity;
  if (paramRake === "all") {
    return true;
  }
  const trainRake = parseFloat(paramRake) || "948004232864";
  const { rake, original_rake: orig } = trajectory || {};
  return (
    new RegExp(`^${trainRake}$`).test(orig) ||
    new RegExp(`^${trainRake}$`).test(rake) ||
    new RegExp(`${trainRake};`).test(rake) ||
    new RegExp(`;${trainRake}`).test(rake)
  );
};

// Temporar info related to Ligfe Quality, see SBAHNMW-926
// const diversityEndDate = new Date('2124-04-28T00:00:00+02:00');
const lifeQualityStartDate = new Date("2024-04-15T00:01:00+02:00");
const isLifeQuality = () => {
  if (params.lifequality) {
    return true;
  }
  return lifeQualityStartDate < Date.now(); // &&  Date.now() < diversityEndDate;
};
const isLifeQualityTrain = (trajectory) => {
  const paramRake = params.lifequality;
  if (paramRake === "all") {
    return true;
  }
  const trainRake = parseFloat(paramRake) || "948004231221";
  const { rake } = trajectory || {};
  return new RegExp(`${trainRake}`).test(rake);
};

const styleSchematic =
  REACT_APP_STYLE_SCHEMATIC || "tralis_munich_schematic_v3";

// SBAHN Munchen
const config = {
  ...common,
  title: "Live Map der S-Bahn München",
  initialStation: 8000261,
  lineIcons,
  defaultLineIcon: lineIcons.s,
  mapLineIcons,
  stationIcons: {
    ...common.stationIcons,
  },
  getIconScaleFromRes: (view, layer) => {
    const dfltScale = 0.6;
    if (layer.mode === RealtimeModes.TOPOGRAPHIC) {
      return dfltScale;
    }
    const minIconScale = 0.45;
    const res = view.getResolution();
    const resZoom7 = view.getResolutionForZoom(7);
    const resZoom8 = view.getResolutionForZoom(8);
    let scale = dfltScale;
    if (res > resZoom7) {
      const dynamicScale = resZoom7 / res;
      scale = dynamicScale < minIconScale ? minIconScale : dynamicScale;
    } else if (res < resZoom8) {
      scale = resZoom8 / res;
    }
    return parseFloat(scale.toFixed(1));
  },
  topographic: {
    ...common.topographic,
    x: 1288648.23,
    y: 6129702.78,
    z: 10,
    minZoom: 10,
    maxZoom: 14,
    extent: topographicExtent,
    dataExtent: [1179506, 6055574, 1368764, 6200498],
    getZoomFromPreviousMode: (zoom) => {
      return zoom + 2;
    },
    events: eventsTopographic,
  },
  schematic: {
    ...common.schematic,
    x: 2406279,
    y: 1722649,
    z: 5.5,
    minZoom: 4,
    maxZoom: 8.5,
    extent: schematicExtent,
    dataExtent: [
      -343587.64423760027, -275777.19694664655, 5505850.071994037,
      3635429.354084775,
    ],
    getZoomFromPreviousMode: (zoom) => {
      if (zoom - 5 >= 8) {
        return 5;
      }
      return zoom - 5;
    },
    style: styleSchematic,
    styleUrl: getStyleUrl(styleSchematic),
    paintLineWidthHighlight: {
      stops: [
        [5, 4],
        [6, 8],
        [10, 40],
        // [6, 4],
        // [10.5, 8],
        // [11.5, 12],
      ],
    },
    events: eventsSchematic,
  },

  sortVehicles: (trajectory, trajectory2) => {
    const vehicle1 = trajectory.properties;
    const vehicle2 = trajectory2.properties;

    // Firefox doesn't react the same when we test only the first trajectory.
    // So we have to a test both trajectories.
    if (
      (isLifeQuality() && isLifeQualityTrain(vehicle1)) ||
      (isDiversity() && isDiversityTrain(vehicle1))
    ) {
      return -1;
    }

    if (
      (isLifeQuality() && isLifeQualityTrain(vehicle2)) ||
      (isDiversity() && isDiversityTrain(vehicle2))
    ) {
      return 1;
    }
    return 0;
  },

  // Style functions
  standingVehicleStyleFunc,
  getCustomMapIconForTrajectory: (trajectory, lineImgKey, lineImages) => {
    if (
      trajectory &&
      (trajectory.isDiversity === undefined ||
        trajectory.isDiversity === true) &&
      lineImgKey &&
      lineImages &&
      isDiversity() &&
      isDiversityTrain(trajectory)
    ) {
      // eslint-disable-next-line no-param-reassign
      trajectory.isDiversity = true;
      return {
        key: `Diversity${lineImgKey}`,
        arrow: lineImages[lineImgKey]?.arrow,
        circle: lineImages.Diversity?.circle,
        deltaScale: params.scale ? parseFloat(params.scale) : 1.5,
      };
    }

    if (
      trajectory &&
      (trajectory.isLifeQuality === undefined ||
        trajectory.isLifeQuality === true) &&
      lineImgKey &&
      lineImages &&
      isLifeQuality() &&
      isLifeQualityTrain(trajectory)
    ) {
      // eslint-disable-next-line no-param-reassign
      trajectory.isLifeQuality = true;
      return {
        key: `LifeQuality${lineImgKey}`,
        arrow: lineImages[lineImgKey]?.arrow,
        circle: lineImages.LifeQuality?.circle,
        deltaScale: params.scale ? parseFloat(params.scale) : 1.5,
      };
    }

    if (trajectory) {
      // eslint-disable-next-line no-param-reassign
      trajectory.isDiversity = false;
    }
    return null;
  },

  // Render functions
  renderAffectedRouteTitle: (affectedRoute) => {
    let start = "";
    let end = "";
    if (affectedRoute?.properties?.stops?.length) {
      const { stops } = affectedRoute.properties;
      start = stops[0]?.name || "";
      end = stops[stops.length - 1]?.name || "";
      // München Ost" must display "Ostbahnhof"
      start = start.replace(/münchen ost/i, "Ostbahnhof");
      end = end.replace(/münchen ost/i, "Ostbahnhof");
      // Remove münchen prefix, see SBAHNMW-660.
      start = start.replace(/münchen( |-)/i, "");
      end = end.replace(/münchen( |-)/i, "");
    }
    return `${start} ↔ ${end}`;
  },

  /**
   * Notifications functionnalities.
   */
  useNotifications: true,

  /**
   * Render functions components
   */
  renderRouteHeader: ({ vehicle } = {}) => {
    if (isDiversity() && isDiversityTrain(vehicle)) {
      return <DiversityHeader />;
    }
    if (isLifeQuality() && isLifeQualityTrain(vehicle)) {
      return <LifeQualityHeader />;
    }
    return null;
  },
  maskInfo: false,
  stationIconsLegendPopup: true,
  stationIconsLegend: true,
};

// Custom permalink parameter
if (params.onlymap === "true") {
  config.bottomContainer = false;
  config.mapControls = false;
  config.legalLinks = false;
}

// videowall mode (SBAHNMW-718)
if (params.videowall === "true") {
  config.xyzParam = false;
  config.mapControls = false;
  config.maskInfo = false;
  config.platformInfo = false;
  config.routeInfo = false;
  config.departuresList = false;
  config.disruptionInfoCloseButton = false;
  config.mapUnderBottomContainer = true; // The map will not be resized when the bottom container is open (only desktop)
  config.navigatorClass = "videowall";
}

export default config;
